import Lightbox from '@/Components/Common/Lightbox.jsx';
import YaKCatchPhraseBox from '@/Pages/Configurator/Components/Themed/YaKCatchPhraseBox.jsx';
import ImagesStyle from '@/Styles/images.js';
import {Box, Link as MuiLink, Typography} from '@mui/material';
import React, {memo} from 'react';

const decors = [
    {image: '/theme/ya-k/images/pages/configurator/decors/photos-sdb-Photo01.jpg', width: 1000, height: 660},
    {image: '/theme/ya-k/images/pages/configurator/decors/photos-sdb-Photo02.jpg', width: 1000, height: 1000},
    {image: '/theme/ya-k/images/pages/configurator/decors/photos-sdb-Photo03.jpg', width: 1000, height: 660},
];

let styles = {
    shower_icon: (theme) => ({
        position: 'relative',
        display: 'inline-block',
        '&::after': {
            ...ImagesStyle.background_contain('/theme/ya-k/images/icons/douche.svg'),
            content: '""',
            zIndex: 999,
            position: 'absolute',
            top: 0,
            right: '10%',
            transform: 'translateY(-75%)',
            width: '57px',
            height: '64px',
            [theme.breakpoints.up('md')]: {
                width: '85px',
                height: '96px',
            },
        },
    }),
};

const YaKBedroomsDetails = () => {
    return (
        <>
            <Box sx={{mb: {xs: 4, md: 6}, ml: '44px'}}>
                <Typography variant="h5" component="h2" sx={{textWrap: 'balance'}}>
                    Douche et baignoire <strong>et que ça chante !</strong>
                </Typography>
            </Box>

            <Lightbox
                id={'gallery-bedroom-organisation'}
                sx={(theme) => ({
                    display: 'grid',
                    gridTemplateAreas: `
                        "a"
                        "b"
                        "c"
                        "d"
                        "e"`,
                    gap: 5,
                    height: '100%',
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        gridTemplateAreas: `
                        "a a"
                        "b c"
                        "d d"
                        "e e"`,
                    },
                    [theme.breakpoints.up('md')]: {
                        gridTemplateColumns: '1fr 1fr',
                        gridTemplateAreas: `
                            "a c"
                            "b c"
                            "d e"`,
                        gap: 8,
                    },
                    [theme.breakpoints.up('lg')]: {
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridTemplateAreas: `
                            "a c d"
                            "b c d"
                            "b c e"`,
                    },
                })}
            >
                <YaKCatchPhraseBox
                    sx={{gridArea: 'a', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <Typography variant="subtitle2" component="p" sx={{textAlign: 'justify'}}>
                        Une douche pour les pressés et les économes, une baignoire pour la détente et les soins
                        thérapeutiques. Chacun ses bienfaits, quel sera votre préféré&nbsp;? Et la double vasque, on en
                        parle&nbsp;?
                    </Typography>
                </YaKCatchPhraseBox>

                <MuiLink
                    href={decors[0].image}
                    data-pswp-width={decors[0].width}
                    data-pswp-height={decors[0].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={(theme) => ({
                        display: 'inline-block',
                        gridArea: 'b',
                        ...styles.shower_icon(theme),
                    })}
                >
                    <Box component="img" src={decors[0].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <MuiLink
                    href={decors[1].image}
                    data-pswp-width={decors[1].width}
                    data-pswp-height={decors[1].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'c',
                    }}
                >
                    <Box component="img" src={decors[1].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <MuiLink
                    href={decors[2].image}
                    data-pswp-width={decors[2].width}
                    data-pswp-height={decors[2].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'd',
                    }}
                >
                    <Box component="img" src={decors[2].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <YaKCatchPhraseBox
                    sx={{gridArea: 'e', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <Typography variant="subtitle2" component="p" sx={{textAlign: 'justify'}}>
                        Côté déco, contemporaine avec de beaux effets béton ou ambiance authentique avec des effets
                        carreaux ciment… À vous de réaliser l'atmosphère qui vous donnera toute l'énergie pour aborder
                        votre quotidien&nbsp;!
                    </Typography>
                </YaKCatchPhraseBox>
            </Lightbox>
        </>
    );
};

export default memo(YaKBedroomsDetails);
